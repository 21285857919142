export const COLOR_MAGENTA_HEX = '#ff00ff';
export const COLOR_GREEN_HEX = '#008000';
export const COLOR_BLUE_HEX = '#0000ff';
export const COLOR_ORANGE_HEX = '#ffa500';
export const COLOR_PURPLE_HEX = '#800080';
export const COLOR_CYAN_HEX = '#00ffff';
export const COLOR_GOLD_HEX = '#ffd700';
export const COLOR_BUBBLES_HEX = '#e6f7ff';
export const COLOR_BRIGHT_GRAY_HEX = '#ececeb';
export const COLOR_CULTURED_HEX = '#f7f7f7';
export const COLOR_WITHE_HEX = '#fff';
export const COLOR_LIGHT_GREY_HEX = '#d3d3d3';
