import { ApiConstruct } from '../../interfaces/Construct';
import axios from 'axios';
import BaseService from '../BaseService';
import Configuration from '../ServiceConfig';
import ConstructsService from '../ConstructsService';
import { FiltersArray } from '../../interfaces/Api';
import { Map } from '../../interfaces/Map';
import ProjectsService from '../ProjectsService';

interface ApiLink {
	source: number,
	target: number,
	count: number
}

interface ApiResult {
	nodes: [ApiConstruct],
	links: [ApiLink]
}

class ProjectMapService extends BaseService {
	config: Configuration;
	path: string;
	token: string | null;
	projectId: number;

	constructor(projectId: number) {
		super();
		this.config = new Configuration();
		this.path = `${this.config.baseurl}projects/${projectId}/map/`;
		this.token = localStorage.getItem('token');
		this.projectId = projectId;
	}

	async get(filters: FiltersArray): Promise<Map | void> {
		let params = {};

		if (filters) {
			params = { ...params, ...filters };
		}

		params = { ...params };

		return axios
			.get(this.path, {
				params,
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then((response) => {
				return this.parseDataTable(response.data);
			})
			.catch((err) => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async parseDataTable(result: ApiResult): Promise<Map> {
		const constructsService = new ConstructsService();
		const constructsTypes = constructsService.getAllConstructsType();
		const projectsService = new ProjectsService();
		const constructTypesColors = await projectsService.getConstructTypeColors(this.projectId);

		return {
			nodes: result.nodes.map((node: ApiConstruct) => {
				const constructType = constructsTypes.find(constructType => constructType.id === node.type);
				const constructTypeColor = constructTypesColors && constructTypesColors.find(constructTypeColor => constructTypeColor.type === node.type);

				return {
					id: node.id.toString(),
					label: node.name.toString(),
					color: constructTypeColor ? constructTypeColor.color : constructType?.color,
				};
			}),
			links: result.links.map((link: ApiLink) => {
				return {
					source: link.source.toString(),
					target: link.target.toString(),
					count: link.count,
				};
			}),
		};
	}
}

export default ProjectMapService;
