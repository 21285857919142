import {
	COLOR_BLUE,
	COLOR_CYAN,
	COLOR_GOLD,
	COLOR_GREEN,
	COLOR_MAGENTA,
	COLOR_ORANGE,
	COLOR_PURPLE
} from './ColorsName';

import {
	COLOR_BLUE_HEX,
	COLOR_CYAN_HEX,
	COLOR_GOLD_HEX,
	COLOR_GREEN_HEX,
	COLOR_MAGENTA_HEX,
	COLOR_ORANGE_HEX,
	COLOR_PURPLE_HEX
} from './ColorsHex';

let map = new Map();

map.set(COLOR_GREEN_HEX, COLOR_GREEN);
map.set(COLOR_ORANGE_HEX, COLOR_ORANGE);
map.set(COLOR_MAGENTA_HEX, COLOR_MAGENTA);
map.set(COLOR_PURPLE_HEX, COLOR_PURPLE);
map.set(COLOR_BLUE_HEX, COLOR_BLUE);
map.set(COLOR_GOLD_HEX, COLOR_GOLD);
map.set(COLOR_CYAN_HEX, COLOR_CYAN);

export default map;
