import {
	COLOR_BLUE_HEX,
	COLOR_CYAN_HEX,
	COLOR_GOLD_HEX,
	COLOR_GREEN_HEX,
	COLOR_MAGENTA_HEX,
	COLOR_ORANGE_HEX,
	COLOR_PURPLE_HEX
} from './ColorsHex';

const setTagColor = (construcType) => {
	switch (construcType) {
		case 1:
			return COLOR_GREEN_HEX;
		case 2:
			return COLOR_ORANGE_HEX;
		case 3:
			return COLOR_MAGENTA_HEX;
		case 4:
			return COLOR_PURPLE_HEX;
		case 5:
			return COLOR_BLUE_HEX;
		case 6:
			return COLOR_CYAN_HEX;
		case 7:
			return COLOR_GOLD_HEX;
		default:
			return '';
	}
};

export default setTagColor;
