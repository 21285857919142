import { ApiMergeHistoryItem, Construct, MergeHistoryItem } from '../../interfaces/Construct';
import { Filters, Sorter } from '../../interfaces/Api';
import axios from 'axios';
import BaseService from '../BaseService';
import Configuration from '../ServiceConfig';
import ConstructsService from '../ConstructsService';
import { MergedNode } from '../../interfaces/Map';

interface Response {
	data: Construct[];
	totalCount: number;
}

class ProjectConstructsService extends BaseService {
	config: Configuration;
	path: string;
	token: string | null;

	constructor(projectId: number) {
		super();
		this.config = new Configuration();
		this.path = `${this.config.baseurl}projects/${projectId}/constructs/`;
		this.token = localStorage.getItem('token');
	}

	private parseDataTable(results: any[]): Construct[] {
		return results.map(object => {
			return {
				id: object.id,
				name: object.name,
				description: object.description,
				createdAt: object.createdAt,
				disabled: object.disabled ?? false,
				type: ConstructsService.getConstructTypeById(object.type),
			};
		});
	}

	async getAllWithoutPagination(): Promise<Response | void> {
		return axios
			.get(this.path, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return {
					data: this.parseDataTable(response.data.rows),
					totalCount: response.data.count,
				};
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async getAll(filters: Filters | null, sorter: Sorter | null, limit: number | null = 10, offset: number | null = 0): Promise<Response | void> {
		let params = {};

		if (filters) {
			Object.entries(filters).forEach(([key, value]) => {
				if (value) {
					params = { ...params, [key]: value };
				}
			});
		}

		if (sorter && sorter.sort) {
			params = { ...params, ...sorter };
		}

		params = { ...params, limit, offset };

		return axios
			.get(this.path, {
				params,
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return {
					data: this.parseDataTable(response.data.rows),
					totalCount: response.data.count,
				};
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async getLinks(id: number, transcriptId: number | null): Promise<Construct[] | void> {
		let params = {};

		if (transcriptId) {
			params = { ...params, transcriptId };
		}

		return axios
			.get(`${this.path}${id}/links`, {
				params,
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async delete(id: number) {
		return axios
			.delete(`${this.path}${id}`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async update(item: Construct[]) {
		return axios
			.put(this.path, item, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async addLink(id: number, idConstructToLink: number, transcriptId: number | null) {
		const payload = {
			construct: {
				id: idConstructToLink,
			},
			transcript: {
				id: transcriptId,
			},
		};

		return axios
			.post(`${this.path}${id}/links`, payload, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async removeLink(id: number, idConstructToLink: number, transcriptId: number | null) {
		const payload = {
			construct: {
				id: idConstructToLink,
			},
			transcript: {
				id: transcriptId,
			},
		};

		return axios
			.request({
				url: `${this.path}${id}/links`,
				method: 'delete',
				data: payload,
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async addMerges(items: MergedNode[]) {
		const payload = items;

		return axios
			.post(`${this.path}merge`, payload, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async mergeHistory(): Promise<MergeHistoryItem[] | void> {
		return axios
			.get(`${this.path}merge-history`, {
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response.data.map((item: ApiMergeHistoryItem) => ({
					...item,
					construct1: {
						...item.construct1,
						type: ConstructsService.getConstructTypeById(item.construct1.type),
					},
					construct2: {
						...item.construct2,
						type: ConstructsService.getConstructTypeById(item.construct2.type),
					},
					newConstruct: {
						...item.newConstruct,
						type: ConstructsService.getConstructTypeById(item.newConstruct.type),
					},
				}));
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}

	async unmerge(id: number) {
		return axios
			.request({
				url: `${this.path}${id}/unmerge`,
				method: 'post',
				headers: {
					Authorization: `Bearer ${this.token}`,
				},
			})
			.then(response => {
				return response;
			})
			.catch(err => {
				if (err.response) {
					this.handleResponseError(err.response);
				} else {
					this.handleError(err);
				}
			});
	}
}

export default ProjectConstructsService;
