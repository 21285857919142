import './ProjectMap.less';
import { ArrowsAltOutlined, ExportOutlined, FieldTimeOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Form, List, Modal, Row, Select, Slider, Spin, Tag, Tooltip, TreeSelect } from 'antd';
import { ConstructType, MergeHistoryItem } from '../../interfaces/Construct';
import { Map as MapI, MapLink, MapNode, MergedNode } from '../../interfaces/Map';
import React, { useContext, useEffect, useState } from 'react';
import { AnalystRole } from '../../interfaces/Analyst';
import ColorPalettePicker from '../../components/ColorPalettePicker/ColorPalettePicker';
import ConstructsService from '../../services/ConstructsService';
import { ErrorContext } from '../../providers/ErrorProvider';
import { Graph } from '@antv/g6';
import { IColor } from 'react-color-palette';
import Map from '../../components/Map/Map';
import moment from 'moment';
import PROJECT_ROUTES from '../../constants/project-constants';
import ProjectConstructsService from '../../services/Project/ProjectConstructsService';
import { ProjectLayoutContext } from '../../providers/ProjectLayoutProvider';
import ProjectMapService from '../../services/Project/ProjectMapService';
import ProjectSegmentsService from '../../services/Project/ProjectSegmentsService';
import ProjectsService from '../../services/ProjectsService';
import ProjectTypesService from '../../services/Project/ProjectTypesService';
import { Segment } from '../../interfaces/Segment';
import { Type } from '../../interfaces/Type';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../providers/UserProvider';

const dateFormat = 'MM/DD/YYYY';

const ProjectMap: React.FC = () => {
	const { setError } = useContext(ErrorContext);
	const constructsService = new ConstructsService();
	const projectsService = new ProjectsService();
	const constructsTypes = constructsService.getAllConstructsType();
	const [filters, setFilters] = useState<{constructType: ConstructType[], segmentId: string[], typeOptionId: number[]}>({ constructType: [], segmentId: [], typeOptionId: [] });
	const [loading, setLoading] = useState<boolean>(false);
	const [map, setMap] = useState<void | null | MapI>(null);
	const [filteredMap, setFilteredMap] = useState<null | {nodes: MapNode[], links: MapLink[]}>(null);
	const [segments, setSegments] = useState<Segment[]>([]);
	const [types, setTypes] = useState<Type[]>([]);
	const { setRoutes, setActive } = useContext(ProjectLayoutContext);
	const { projectId } = useParams<{ projectId: string }>();
	const projectConstructsService = new ProjectConstructsService(+(projectId||'0'));
	const mapService = new ProjectMapService(+(projectId||'0'));
	const segmentsService = new ProjectSegmentsService(+(projectId||'0'));
	const typesService = new ProjectTypesService(+(projectId||'0'));
	const [graph, setGraph] = useState<null | Graph>(null);
	const [maxThreshold, setMaxThreshold] = useState(1);
	const [form] = Form.useForm();
	const [mergedNodes, setMergedNodes] = useState<MergedNode[]>([]);
	const [mergeHistory, setMergeHistory] = useState<MergeHistoryItem[]>([]);
	const [isCollapseHistoryModalOpen, setIsCollapseHistoryModalOpen] = useState(false);
	const [isMapColorsModalOpen, setIsMapColorsModalOpen] = useState(false);
	const [selectedConstructType, setSelectedConstructType] = useState<ConstructType>(constructsTypes[0]);
	const [loadingColor, setLoadingColor] = useState(false);
	const [mapColors, setMapColors] = useState<ConstructType[]>([]);
	const [defaultMapColors, setDefaultMapColors] = useState<ConstructType[]>([]);
	const [threshold, setThreshold] = useState(1);
	const { user } = useContext(UserContext);

	const getMap = async () => {
		setLoading(true);
		form.setFieldsValue({ Threshold: 1 });
		setThreshold(1);

		try {
			const result = await mapService.get(filters);

			setMap(result);

			if (result) {
				const newLinks = result.links.filter(link => link.count >= 1);
				const newNodes = result.nodes.filter(node => newLinks.some(link => link.source === node.id || link.target === node.id));

				setFilteredMap({ nodes: newNodes, links: newLinks });

				if (result.links.length) {
					const maxCount = Math.max(...result.links.map(link => link.count));

					setMaxThreshold(maxCount);
				}
			}
		} catch (err) {
			setMap(null);
			setFilteredMap(null);
			setError(err as string);
		}

		setLoading(false);
	};

	const getSegments = async () => {
		setLoading(true);

		try {
			const result = await segmentsService.getAllWithoutPagination();

			if (result) {
				setSegments(result.data);
			}
		} catch (err) {
			setError(err as string);
		}

		setLoading(false);
	};

	const getTypes = async () => {
		setLoading(true);

		try {
			const result = await typesService.getAllWithoutPagination();

			if (result) {
				setTypes(result.data.filter(type => type.options && type.options.length));
			}
		} catch (err) {
			setError(err as string);
		}

		setLoading(false);
	};

	const getMapColors = async () => {
		setLoading(true);

		try {
			if (projectId) {
				const result = await projectsService.getConstructTypeColors(+projectId);

				const constructTypeColors = constructsTypes.map(constructType => {
					const constructTypeColor = result.find(constructTypeColor => constructTypeColor.type === constructType.id);

					return ({
						...constructType,
						color: constructTypeColor?.color || constructType.color,
					});
				});

				setMapColors(constructTypeColors);
				setDefaultMapColors(constructTypeColors);
			}
		} catch (err) {
			setError(err as string);
		}

		setLoading(false);
	};

	useEffect(() => {
		if (projectId) {
			projectsService.get(+projectId).then(async data => {
				await getSegments();
				await getTypes();
				await getMapColors();
				const actualRoutes = [
					{
						path: 'projects',
						breadcrumbName: 'Projects',
					},
					{
						path: projectId,
						breadcrumbName: data.name,
					},
					{
						path: 'map',
						breadcrumbName: 'Map',
					},
				];

				setRoutes(actualRoutes);
				setActive(PROJECT_ROUTES.MAP);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		getMap();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters]);

	const saveMerges = async () => {
		try {
			await projectConstructsService.addMerges(mergedNodes);
			setMergedNodes([]);
			await getMap();
		} catch (err) {
			setError(err as string);
		}
	};

	const addMerge = (construct1Id: number, construct2Id: number, id: number, name: string, description: string, type: number) => {
		setMergedNodes(prevState => ([...prevState, {
			construct1: { id: +construct1Id },
			construct2: { id: +construct2Id },
			newConstruct: {
				id,
				name,
				description,
				type,
			},
		}]));
	};

	const openMergeHistory = async () => {
		try {
			const items = await projectConstructsService.mergeHistory();

			if (items) {
				setMergeHistory(items);
				setIsCollapseHistoryModalOpen(true);
			}
		} catch (err) {
			setError(err as string);
		}
	};

	const unmerge = async (constructId: number) => {
		try {
			await projectConstructsService.unmerge(constructId);
			await getMap();
			handleCloseCollapseHistoryModal();
		} catch (err) {
			setError(err as string);
		}
	};

	const handleCloseCollapseHistoryModal = () => {
		setIsCollapseHistoryModalOpen(false);
	};

	const changeMapColors = () => {
		setIsMapColorsModalOpen(true);
	};

	const handleCloseMapColorsModal = () => {
		setIsMapColorsModalOpen(false);
		setMapColors(defaultMapColors);
		setSelectedConstructType(defaultMapColors[0]);
	};

	const onSelectColor = (constructType: ConstructType) => {
		setSelectedConstructType(constructType);
		setLoadingColor(true);

		setTimeout(() => {
			setLoadingColor(false);
		}, 500);
	};

	const onChangeComplete = (color: IColor) => {
		setMapColors(mapColors.map(mapColor => {
			if (mapColor.id === selectedConstructType.id) {
				return ({
					...mapColor,
					color: color.hex,
				});
			}

			return mapColor;
		}));
	};

	const onSaveNewMapColors = async () => {
		try {
			if (projectId) {
				await projectsService.changeConstructTypeColors(+projectId, mapColors);
			}

			await getMap();
			setDefaultMapColors(mapColors);
			setIsMapColorsModalOpen(false);
			setSelectedConstructType(mapColors[0]);
		} catch (err) {
			setError(err as string);
		}
	};

	const onTresholdChange = (value: number) => {
		setLoading(true);
		setTimeout(() => {
			if (filteredMap && map) {
				const newLinks = map.links.filter(link => link.count >= value);
				const newNodes = map.nodes.filter(node => newLinks.some(link => link.source === node.id || link.target === node.id));

				setFilteredMap({ nodes: newNodes, links: newLinks });
			}

			setLoading(false);
		}, 100);
		setThreshold(value);
	};

	return (
		<>
			{/*ASK: I’m not sure where it would be best to place the modal, whether in this component or in the map component.  */}
			<div className='project-map'>
				<div className='filters-container'>
					<Form layout='vertical' form={form}>
						<Row gutter={24} align='bottom'>
							<Col span={4}>
								<Form.Item label='Construct Types' name='selectedConstructTypes'>
									<Select
										mode='multiple'
										allowClear
										placeholder='All'
										onChange={values => setFilters({ ...filters, constructType: values as unknown as ConstructType[] })}
										loading={loading}
										disabled={loading}
										options={constructsTypes.map((construct) => ({ label: construct.value, value: construct.id }))}
									/>
								</Form.Item>
							</Col>
							<Col span={4}>
								<Form.Item label='Segments' name='selectedSegments'>
									<Select
										mode='multiple'
										allowClear
										placeholder='All'
										onChange={values => setFilters({ ...filters, segmentId: values as string[] })}
										loading={loading}
										disabled={loading}
										options={segments.map((segment) => ({ label: segment.name, value: segment.key }))}
									/>
								</Form.Item>
							</Col>
							<Col span={4}>
								<Form.Item label='Types' name='Types'>
									<TreeSelect
										onChange={values => setFilters({ ...filters, typeOptionId: values as number[] })}
										treeCheckable
										showCheckedStrategy={TreeSelect.SHOW_CHILD}
										placeholder='All'
										loading={loading}
										treeData={types.map(type => ({
											title: type.name,
											name: 'parent' + type.key,
											key: 'parent' + type.key,
											children: type.options.map(option => ({
												title: option.name,
												value: option.id,
												key: option.id,
											})),
										}))}
									/>
								</Form.Item>
							</Col>
							<Col span={3}>
								<Form.Item label='Threshold' name='Threshold'>
									<Slider
										defaultValue={threshold}
										min={1}
										max={maxThreshold}
										disabled={maxThreshold === 1}
										onAfterChange={onTresholdChange}
									/>
								</Form.Item>
							</Col>
							<Col span={1}>
								<Form.Item label='' name='ThresholdStatus'>
									<span>{`${threshold}/${maxThreshold}`}</span>
								</Form.Item>
							</Col>
							<Col span={4}>
								{
									(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) && (
										<Form.Item>
											<Button type='link' block onClick={changeMapColors}>Map colors</Button>
										</Form.Item>
									)
								}
							</Col>
							<Col span={4}>
								<Form.Item>
									<Button type='primary' block onClick={() => { graph && graph.downloadImage(); }}>
										<ExportOutlined /> Export this view
									</Button>
								</Form.Item>
							</Col>
						</Row>
					</Form>
				</div>
				{
					(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST) && (
						<div className='filters-container white-background'>
							<Form layout='vertical'>
								<Row gutter={16} align='bottom'>
									<Col span={18}>
										<Tooltip placement='top' title='Show merge history'>
											<FieldTimeOutlined style={{ color: '#1991EB', fontSize: 20 }} onClick={openMergeHistory} />
										</Tooltip>
									</Col>
									<Col span={3}>
										<Form.Item>
											<Button type='link' block onClick={async () => { setMergedNodes([]); await getMap(); }} disabled={!mergedNodes.length}>
											Cancel
											</Button>
										</Form.Item>
									</Col>
									<Col span={3}>
										<Form.Item>
											<Button type='primary' block onClick={saveMerges} disabled={!mergedNodes.length}>
												<SaveOutlined /> Save map
											</Button>
										</Form.Item>
									</Col>
								</Row>
							</Form>
						</div>
					)
				}
				{filteredMap && !loading && <Map
					map={filteredMap}
					setExternalGraph={setGraph}
					allowMergeCreation={(user?.role === AnalystRole.GLOBAL_PARTNER_ADMIN || user?.role === AnalystRole.ANALYST)}
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore: Disables type checking for the following line
					addMerge={addMerge}
				/>}
				<Modal
					title={'Collapse history'}
					centered
					open={isCollapseHistoryModalOpen}
					width={500}
					destroyOnClose
					onCancel={handleCloseCollapseHistoryModal}
					footer={[]}
					className='collapse-history'
				>
					<List
						dataSource={mergeHistory.sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf())}
						renderItem={merge => (
							<List.Item className='list-item'>
								<div className='merged-box'>
									<Tag className='new-construct-tag' style={{ border: 'solid 1px ' + merge.newConstruct.type?.color, color: merge.newConstruct.type?.color }}>
										{merge.newConstruct.name}
									</Tag>
									{
										!!merge.canBeUnmerged && (
											<Tooltip placement='top' title='Unmerge'>
												<div className='unmerge-button'>
													<ArrowsAltOutlined className='arrow' onClick={() => unmerge(merge.newConstruct.id)} />
												</div>
											</Tooltip>
										)
									}
								</div>
								<div className='constructs-history'>
									{
										<div>
											<Tag className='new-construct-tag' style={{ border: 'solid 1px ' + merge.construct1.type?.color, color: merge.construct1.type?.color }}>
												{merge.construct1.name}
											</Tag>
											<Tag className='new-construct-tag' style={{ border: 'solid 1px ' + merge.construct2.type?.color, color: merge.construct2.type?.color }}>
												{merge.construct2.name}
											</Tag>
										</div>
									}
								</div>
								<div className='author'>
									{`by ${merge.createdBy.firstName} ${merge.createdBy.lastName} on ${moment(merge.createdAt).format(dateFormat)}`}
								</div>
							</List.Item>
						)}
					/>
				</Modal>
				<Modal
					title={'Customize your map default colors'}
					centered
					open={isMapColorsModalOpen}
					width={500}
					destroyOnClose
					onCancel={handleCloseMapColorsModal}
					footer={[
						<Button key='back' onClick={handleCloseMapColorsModal}>
						Cancel
						</Button>,
						<Button
							key='submit'
							type='primary'
							onClick={onSaveNewMapColors}
						>
						Save colors
						</Button>,
					]}
					className='default-colors'
				>
					<div className='colors-box'>
						<div className='constructs'>
							{
								mapColors.map(constructType => (
									<div key={constructType.id} className='construct-box'>
										<div
											className='color-box'
											style={{ backgroundColor: constructType.color, opacity: constructType.id === selectedConstructType.id ? 1 : 0.5 }}
											onClick={() => onSelectColor(constructType)}
										/>
										<Tag className='tag-box' style={{ border: 'solid 1px ' + constructType.color, color: constructType.color }}>
											{constructType.value}
										</Tag>
									</div>
								))
							}
						</div>
						<div className='color-palette'>
							{
								!loadingColor ? (
									<ColorPalettePicker initialColor={selectedConstructType?.color || 'black'} onChangeComplete={onChangeComplete} />
								) : (
									<Spin size='large' />
								)
							}
						</div>
					</div>
				</Modal>
			</div>
		</>

	);
};

export default ProjectMap;
