import './SidebarOptionsConstructs.less';

import {
	COLOR_BRIGHT_GRAY_HEX,
	COLOR_BUBBLES_HEX
} from '../../utils/ColorsHex';
import { DeleteOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { Tag, Typography } from 'antd';
import MapColors from '../../utils/MapColorsForTag';

const SidebarOptionsConstructs = ({
	constructType,
	constructList,
	removeSelectedConstruct,
}) => {
	const [collapsable, setCollapsable] = useState(false);
	const [constructs, setConstructs] = useState([]);
	const [selectedConstructs, setSelectedConstructs] = useState([]);

	const { Text } = Typography;

	const handleCollapsable = () => {
		setConstructs(constructList.length > 0 ? constructList : []);
		setCollapsable(true);
	};

	const handleClose = () => {
		setCollapsable(false);
	};

	const markSelectedConstruct = (id) => {
		if (selectedConstructs.some((selected) => selected === id)) {
			selectedConstructs.splice(selectedConstructs.indexOf(id), 1);
			setSelectedConstructs([...selectedConstructs]);
		} else {
			selectedConstructs.push(id);
			setSelectedConstructs([...selectedConstructs]);
		}
	};

	useEffect(() => {
		setConstructs(constructList);
	}, [constructList, selectedConstructs]);

	const count = constructs.filter(
		(c) => c.type.value === constructType.value
	).length;

	return (
		<>
			{
				<div className="SidebarOptionsConstructs">
					{!collapsable && (
						<div
							className="sidebar-header"
							style={{ borderColor: constructType.color }}
							onClick={() => handleCollapsable()}
						>
							<Text className="text">{constructType.value}</Text>
							<Text className="type-count">{count}</Text>
							<div className="arrow-toggle">
								<DownOutlined />
							</div>
						</div>
					)}
					{collapsable && (
						<div>
							<div
								className="sidebar-header"
								style={{ borderColor: constructType.color }}
								onClick={() => handleClose()}
							>
								<Text className="text">
									{constructType.value}
								</Text>
								<Text className="type-count">{count}</Text>
								<div className="arrow-toggle">
									<UpOutlined />
								</div>
							</div>
							{constructs &&
								constructs
									.filter(
										(c) =>
											c.type.value === constructType.value
									)
									.map((element, index) => {
										return (
											<div
												key={element.id}
												style={{
													borderTop:
														index > 0
															? 'none'
															: `solid 1px ${COLOR_BRIGHT_GRAY_HEX}`,
													backgroundColor:
														selectedConstructs &&
														selectedConstructs.some(
															(id) =>
																id ===
																element.id
														)
															? COLOR_BUBBLES_HEX
															: 'white',
												}}
												className="excerpt-component"
												onClick={() =>
													markSelectedConstruct(
														element.id
													)
												}
											>
												<Tag
													color={MapColors.get(
														constructType.color
													)}
													style={{
														cursor: 'pointer',
													}}
												>
													{element.text}
												</Tag>
												{selectedConstructs &&
													selectedConstructs.some(
														(id) =>
															id === element.id
													) && (
													<DeleteOutlined
														onClick={() =>
															removeSelectedConstruct(
																element.id
															)
														}
														className="arrow-toggle"
													/>
												)}
											</div>
										);
									})}
						</div>
					)}
				</div>
			}
		</>
	);
};

export default SidebarOptionsConstructs;
