import {
	COLOR_BLUE_HEX,
	COLOR_CYAN_HEX,
	COLOR_GOLD_HEX,
	COLOR_GREEN_HEX,
	COLOR_MAGENTA_HEX,
	COLOR_ORANGE_HEX,
	COLOR_PURPLE_HEX
} from './ColorsHex';

let map = new Map();

map.set(COLOR_GREEN_HEX, '#F6FFEC');
map.set(COLOR_ORANGE_HEX, '#FFF7E6');
map.set(COLOR_MAGENTA_HEX, '#FEF0F6');
map.set(COLOR_PURPLE_HEX, '#F8F0FF');
map.set(COLOR_BLUE_HEX, '#E6F6FF');
map.set(COLOR_GOLD_HEX, '#FEFBE6');
map.set(COLOR_CYAN_HEX, '#E6FFFB');

export default map;
