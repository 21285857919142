import { Button, Col, Form, Input, Modal, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import ConstructsService from './../../services/ConstructsService';
import ConstructTags from '../../components/ConstructTags/ConstructTags';
import G6 from '@antv/g6';
import SegmentsBreakdownModal from '../SegmentsBreakdownModal/SegmentsBreakdownModal';
import styles from './index.module.less';

let mergesId = -1;

const Map = ({ map, setExternalGraph, allowLinkCreation = false, addLink = null, allowMergeCreation = false, addMerge = null }) => {
	const graphRef = useRef(null);
	const [currentGraph, setCurrentGraph] = useState(graphRef.current);
	const [selectedNodes, setSelectedNodes] = useState([]);
	const [isMergeButtonDisabled, setIsMergeButtonDisabled] = useState(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [constructsId, setConstructsId] = useState(undefined);
	const [form] = Form.useForm();
	const constructsService = new ConstructsService();
	const constructsType = constructsService.getAllConstructsType();
	const [newConstructType, setNewConstructType] = useState(1);
	const divEl = React.useRef(null);
	const { TextArea } = Input;

	const defaultValues = ['drag-combo', 'drag-node', 'drag-canvas', 'click-select'];

	if (allowLinkCreation) {
		defaultValues.push({
			type: 'create-edge',
			key: 'shift',
		});
	}

	const addNewLink = (sourceId, targetId) => {
		addLink && addLink(sourceId, targetId);
	};

	const toolbar = new G6.ToolBar({
		// getContent: () => {
		// 	const outDiv = document.createElement('div');
		// 	outDiv.style.width = '180px';
		// 	outDiv.innerHTML = `<ul class='toolbar'>
		// 		<li code='zoom-in'>example 01</li>
		// 		<li code='zoom-out'>example 01</li>
		// 		<li code='fit-view'>example 01</li>
		// 		<li code='actual-size'>example 01</li>
		// 	  </ul>`
		// 	return outDiv
		// },
		// className: styles.toolbar,
		// handleClick: (code, graph) => {
		// 	switch(code) {
		// 		case 'zoom-in':
		// 			graph.zoom(0.5);
		// 			break;
		// 		case 'zoom-out':
		// 			graph.zoom(-0.5);
		// 			break;
		// 		case 'fit-view':
		// 			graph.fitView();
		// 			break;
		// 		case 'actual-size':
		// 			graph.fitCenter();
		// 			break;
		// 		default:
		// 			break;
		// 	}
		// }
	});

	const minimap = new G6.Minimap({
		container: 'minimap-container',
		size: [160, 70],
	});

	const createCombo = (values) => {
		if (values.name && values.description && newConstructType) {
			const newConstructId = mergesId;
			const newConstructName = values.name;
			const newConstructDescription = values.description;

			addMerge && addMerge(
				selectedNodes[0]._cfg.id,
				selectedNodes[1]._cfg.id,
				newConstructId,
				newConstructName,
				newConstructDescription,
				newConstructType
			);

			const nodeIds = selectedNodes.map(node => node._cfg.id);

			currentGraph.createCombo(
				{
					id: newConstructId.toString(),
					label: newConstructName,
					collapsed: false,
				},
				[...nodeIds]
			);

			mergesId = mergesId - 1;
			setIsMergeButtonDisabled(true);
		}

		handleClose();
	};

	const addCombo = () => {
		if (selectedNodes.length === 2) {
			setIsModalOpen(true);
		}
	};

	const onClickBehavior = (evt, graph) => {
		const { item } = evt;
		const { states, id } = item._cfg;
		let nodes = selectedNodes;

		if (states.includes('selected')) {
			graph.setItemState(item, 'selected', true);
			graph.setItemState(item, 'unselected', false);

			if (!nodes.find(node => node?._cfg?.id === id)) {
				nodes.push(item);
			}

			if (nodes.length > 2) {
				nodes.shift();
			}

			if (nodes.length === 2) {
				setIsMergeButtonDisabled(false);
			}
		} else {
			graph.setItemState(item, 'selected', false);
			graph.setItemState(item, 'unselected', true);
			nodes = nodes.filter(node => node?._cfg?.id !== id);
		}

		setSelectedNodes(nodes);

		nodes.forEach(selectedNode => {
			const node = graph.findById(selectedNode?._cfg?.id);

			if (node) {
				graph.setItemState(node, 'selected', true);
			}
		});
	};

	const onClickEdge = (evt) => {
		setConstructsId({
			constructLeftId: evt.item?.getModel().source,
			constructRightId: evt.item?.getModel().target,
		});
	};

	useEffect(() => {
		const setGraphObj = () => {
			if (graphRef.current != null) {
				return;
			}

			const graph = new G6.Graph({
				renderer: 'svg',
				container: 'container',
				width: divEl.current.offsetWidth,
				height: 600,
				fitView: true,
				fitViewPadding: 50,
				plugins: [toolbar, minimap],
				enabledStack: true,
				defaultCombo: {
					type: 'cRect',
					size: [40, 5],
					style: {
						radius: 6,
						fontSize: 12,
						stroke: 'black',
					},
				},
				modes: {
					default: defaultValues,
				},
				defaultNode: {
					type: 'rect',
					size: [120, 45],
					style: {
						radius: 6,
						fontSize: 12,
					},
				},
				defaultEdge: {
					type: 'line-dash',
					style: {
						lineWidth: 2,
						stroke: '#bae7ff',
					},
				},
				nodeStateStyles: {
					selected: {
						// stroke: 'black',
						lineWidth: 2,
					},
				},
				comboStateStyles: {
					selected: {
						// stroke: 'black',
						lineWidth: 2,
					},
				},
				layout: {
					type: 'force',
					nodeSize: 150,
					preventOverlap: true,
					// nodeStrength: 30,
					// edgeStrength: 0.1,
					// collideStrength: 0.8,
					// alpha: 0.3,
					// alphaDecay: 0.028,
					// alphaMin: 0.01,
					// forceSimulation: null,
				},
			});

			graph.on('combo:dragend', () => {
				graph.getCombos().forEach((combo) => {
					graph.setItemState(combo, 'dragenter', false);
				});
			});

			graph.on('combo:dragenter', (e) => {
				graph.setItemState(e.item, 'dragenter', true);
			});

			graph.on('combo:dragleave', (e) => {
				graph.setItemState(e.item, 'dragenter', false);
			});

			graph.on('combo:mouseenter', (evt) => {
				const { item } = evt;

				graph.setItemState(item, 'active', true);
			});

			graph.on('combo:mouseleave', (evt) => {
				const { item } = evt;

				graph.setItemState(item, 'active', false);
			});

			graph.on('combo:click', (evt) => {
				if (evt.target.get('name') === 'combo-marker-shape') {
					graph.collapseExpandCombo(evt.item);
				}

				onClickBehavior(evt, graph);
			});

			graph.on('edge:click', (evt) => {
				onClickEdge(evt);
			});

			graph.on('node:click', (evt) => {
				onClickBehavior(evt, graph);
			});

			// const refreshDragedNodePosition = (e) => {
			// 	const model = e.item.get('model');

			// 	model.fx = e.x;
			// 	model.fy = e.y;
			// };

			graph.on('node:drag', (evt) => {
				const { item, clientX, clientY } = evt;
				const point = graph.getPointByClient(clientX, clientY);
				const model = item.getModel();

				item.toFront();
				item.updatePosition(point);

				let source = item.getNeighbors('source');

				source = source[0];

				if (source) {
					const targetEdges = source.getEdges();
					let targetEdge = targetEdges.filter(i => {
						if (i.getModel()) {
							const m = i.getModel();

							if (m.target === model.id) {
								return i;
							}
						}
					});

					targetEdge = targetEdge[0];
					const tM = targetEdge.getModel();
					const tEndPoint = tM.endPoint;
					const sNode = graph.findById(tM.source);
					const sLinkPoint = sNode.getLinkPoint(tEndPoint);
					const sAnchorIndex = sLinkPoint.anchorIndex;

					graph.update(targetEdge, {
						sourceAnchor: sAnchorIndex,
					}, true);
				}

				graph.update(item, model);
				graph.paint();

				// const forceLayout = graph.get('layoutController').layoutMethods[0];

				// forceLayout.execute();
				// refreshDragedNodePosition(evt);
			});

			// graph.on('node:dragstart', (e) => {
			// 	graph.layout();
			// 	refreshDragedNodePosition(e);
			// });

			graph.on('node:dragend', () => {
				graph.getCombos().forEach((combo) => {
					graph.setItemState(combo, 'dragenter', false);
				});
				// e.item.get('model').fx = null;
				// e.item.get('model').fy = null;
			});

			graph.on('node:mouseenter', (e) => {
				graph.setItemState(e.item, 'active', true);
			});

			graph.on('node:mouseleave', (e) => {
				graph.setItemState(e.item, 'active', false);
			});

			graph.on('edge:mouseenter', (e) => {
				graph.setItemState(e.item, 'active', true);
			});

			graph.on('edge:mouseleave', (e) => {
				graph.setItemState(e.item, 'active', false);
			});

			graph.on('aftercreateedge', (e) => {
				addNewLink(e.edge._cfg.source._cfg.id, e.edge._cfg.target._cfg.id);
			});

			graph.on('edge:click', (e) => {
				graph.setItemState(e.item, 'selected', true);
			});

			graphRef.current = graph;
			setCurrentGraph(graphRef.current);
			setExternalGraph(graph);
			graph.clear();
			graph.data({
				combos: [],
				edges: map?.links ?? [],
				nodes: map?.nodes.map(node => ({
					...node,
					color: node.color,
					style: {
						// fill: node.color,
						// lineWidth: 0,
					},
					labelCfg: {
						style: {
							fill: node.color,
							fontSize: (100 / node.label.length) + 5,
						},
					},
				})) ?? [],
			});
			graph.render();
			// graph.fitView();
		};

		setGraphObj();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [map]);

	const handleClose = () => {
		setIsModalOpen(false);
		form.resetFields();
		setNewConstructType(1);
		setConstructsId(undefined);
	};

	return (
		<>
			<SegmentsBreakdownModal onClose={handleClose} constructsId={constructsId} />
			<div className={styles.editorContainer} ref={divEl}>
				<div className={styles.editBox} id={'container'} >
					<div style={{ position: 'absolute', right: 0, top: 0 }}>
						{allowMergeCreation && <button onClick={() => addCombo()} disabled={isMergeButtonDisabled}>Merge</button>}
					</div>
					<div style={{ position: 'absolute', right: 0, bottom: 0, marginRight: '20px', marginBottom: '20px' }}>
						<div id="minimap-container" style={{ backgroundColor: 'white', boxShadow: '0 3px 6px -4px rgba(0,0,0,0.12), 0 6px 16px 0 rgba(0,0,0,0.08), 0 9px 28px 8px rgba(0,0,0,0.05)' }}></div>
					</div>
				</div>
			</div>
			<Modal
				title={'Create new construct from merge'}
				centered
				open={isModalOpen}
				width={800}
				destroyOnClose
				onCancel={handleClose}
				footer={[
					<Button key="back" onClick={handleClose}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						htmlType="submit"
						onClick={form.submit}
					>
						Merge
					</Button>,
				]}
			>
				<Form
					form={form}
					onFinish={createCombo}
				>
					<Form.Item
						className="field-name project-construct"
						name="type"
						label="Construct type"
						required
					>
						<Row justify="space-between">
							{constructsType.map(constructType => (
								<Col key={constructType.id}>
									<ConstructTags
										label={constructType.value}
										active={newConstructType === constructType.id ? true : false}
										color={constructType.color}
										onClick={() => setNewConstructType(constructType.id)}
									/>
								</Col>
							))}
						</Row>
					</Form.Item>
					<Form.Item
						className="field-name"
						name="name"
						label="Construct name"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<Input />
					</Form.Item>
					<Form.Item
						style={{ marginBottom: 0 }}
						name="description"
						label="Definition"
						rules={[{ required: true }, { min: 2 }, { max: 50 }]}
					>
						<TextArea className="common-textarea project-construct" rows={3} />
					</Form.Item>
				</Form>
			</Modal>
		</>
	);
};

export default Map;
